/* eslint-disable import/no-unresolved */
import * as getStatic from '@backpackjs/core-nextjs/get-static';

import FourZeroFourTemplate from '../templates/404';
import { PageErrorBoundary, PageHead } from '../_components';

export const getStaticProps = getStatic.props.fourZeroFour;

export default function fourZeroFour({ isPreview, renderSections, ...props }) {
  const fourZeroFourPage = props.page;

  return (
    <PageErrorBoundary>
      <PageHead page={fourZeroFourPage}>
        <FourZeroFourTemplate {...props} renderSections={renderSections} />
      </PageHead>
    </PageErrorBoundary>
  );
}
